<template>
  <AppCollapse class="mb-50">
    <AppCollapseItem
      title
      :is-visible="true"
      class-body="p-0"
      class-header="p-0"
    >
      <template #header>
        <h4 class="card-title font-weight-bolder py-50">
          {{ $t('filters') }}
        </h4>
      </template>

      <div
        id="invoice-list-filter"
        class="p-0"
      >
        <b-row
          align-v="center"
          align-h="start"
        >
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.from')"
            >
              <flat-pickr
                :value="flightDateFrom"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: flightDateTo ?? null,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:flightDateFrom', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.to')"
            >
              <flat-pickr
                :value="flightDateTo"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: flightDateFrom ?? null,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:flightDateTo', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR loại vé -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.invoiceTicketType')"
            >
              <v-select
                :value="invoiceTicketType"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="invoiceTicketTypeOptions"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:invoiceTicketType', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="resolveColorOfInvoiceTicketType(data.label) || 'secondary'"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.invoiceTickets.type.${data.label}`) }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="resolveColorOfInvoiceTicketType(data.label) || 'secondary'"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.invoiceTickets.type.${data.label}`) }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Trạng thái lập hoá đơn -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.isInvoiced')"
            >
              <v-select
                :value="isInvoicedFilter"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="isInvoicedFilterOptions"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:isInvoicedFilter', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="resolveIsInvoicedColor(data.label) || 'secondary'"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.invoiceTickets.isInvoicedFilter.${data.label}`) }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="resolveIsInvoicedColor(data.label) || 'secondary'"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.invoiceTickets.isInvoicedFilter.${data.label}`) }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Tên khách hàng -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.customerName')"
            >
              <b-form-input
                :value="customerName"
                :placeholder="$t('invoice.ph.customerName')"
                debounce="500"
                :formatter="upperCaseFormatter"
                @input="val => $emit('update:customerName', val)"
                @keyup.enter="refetchData"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR mã đặt chỗ -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.bookingCode')"
            >
              <b-form-input
                :value="bookingCode"
                :placeholder="$t('invoice.ph.bookingCode')"
                :formatter="upperCaseFormatter"
                @input="val => $emit('update:bookingCode', val)"
                @keyup.enter="refetchData"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR Tên khách hàng -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.invoiceTickets.fields.ticketNumber')"
            >
              <b-form-input
                :value="ticketNumber"
                :placeholder="$t('invoice.ph.ticketNumber')"
                :formatter="upperCaseFormatter"
                @input="val => $emit('update:ticketNumber', val)"
                @keyup.enter="refetchData"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="flex-fill"
            align-self="end"
          >
            <div class="d-flex align-items-center justify-content-end gap-1">
              <!-- ANCHOR Button Search -->
              <b-button
                variant="primary"
                size="sm"
                class="p-50"
                @click="refetchData"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="search"
                    size="16"
                  />
                  {{ $t('search') }}
                </span>
              </b-button>

              <!-- ANCHOR Button Clear Search Filters -->
              <b-button
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="onClearFilters"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="delete"
                    size="16"
                  />
                  {{ $t('invoice.create.selectTicket.clearFilter') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { onlyUpperCaseFormatter, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import {
  INVOICE_TICKET_TYPE_OPTIONS, resolveColorOfInvoiceTicketType, isInvoicedFilterOptions, resolveIsInvoicedColor,
} from './useInvoiceTicketHandle'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
    flatPickr,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    flightDateFrom: {
      type: String, default: '',
    },
    flightDateTo: {
      type: String, default: '',
    },
    bookingCode: {
      type: String, default: '',
    },
    ticketNumber: {
      type: String, default: '',
    },
    customerName: {
      type: String, default: '',
    },
    invoiceTicketType: {
      type: [String, null], default: null,
    },
    isInvoicedFilter: {
      type: [Boolean, null], default: null,
    },
  },
  setup(props, { emit }) {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const refetchData = () => {
      emit('refetch-data')
    }

    const onClearFilters = () => {
      emit('refetch-data-without-filters')
    }

    return {
      Vietnamese,
      today,
      onClearFilters,
      upperCaseFormatter,
      refetchData,
      invoiceTicketTypeOptions: [{ label: 'ALL', value: null }].concat(INVOICE_TICKET_TYPE_OPTIONS),
      resolveColorOfInvoiceTicketType,
      isInvoicedFilterOptions,
      resolveIsInvoicedColor,
      onlyUpperCaseFormatter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}

</style>
