var render = function () {
  var _vm$flightDateTo, _vm$flightDateFrom;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50"
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "is-visible": true,
      "class-body": "p-0",
      "class-header": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title font-weight-bolder py-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "p-0",
    attrs: {
      "id": "invoice-list-filter"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.flightDateFrom,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: (_vm$flightDateTo = _vm.flightDateTo) !== null && _vm$flightDateTo !== void 0 ? _vm$flightDateTo : null,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:flightDateFrom', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.flightDateTo,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: (_vm$flightDateFrom = _vm.flightDateFrom) !== null && _vm$flightDateFrom !== void 0 ? _vm$flightDateFrom : null,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:flightDateTo', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.invoiceTicketType')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.invoiceTicketType,
      "options": _vm.invoiceTicketTypeOptions,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceTicketType', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveColorOfInvoiceTicketType(data.label) || 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveColorOfInvoiceTicketType(data.label) || 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.isInvoiced')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.isInvoicedFilter,
      "options": _vm.isInvoicedFilterOptions,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:isInvoicedFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveIsInvoicedColor(data.label) || 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.isInvoicedFilter.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveIsInvoicedColor(data.label) || 'secondary',
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.isInvoicedFilter.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.customerName')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.customerName,
      "placeholder": _vm.$t('invoice.ph.customerName'),
      "debounce": "500",
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:customerName', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.bookingCode,
      "placeholder": _vm.$t('invoice.ph.bookingCode'),
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:bookingCode', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.invoiceTickets.fields.ticketNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.ticketNumber,
      "placeholder": _vm.$t('invoice.ph.ticketNumber'),
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:ticketNumber', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "flex-fill",
    attrs: {
      "cols": "12",
      "md": "auto",
      "align-self": "end"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end gap-1"
  }, [_c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.refetchData
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "delete",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)])], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }